export default
{
    prelude: 'Minden számlázandó tételt kezeltünk',
    currentJob: 'Ellenőrzés és mentés',
    users: [''],
    actions: {
        oro: {
            desc: '',
            actions: [
                'Szemmel átfutjuk, hogy minden adat helyes-e',
                'Ha helyes, akkor elmentjük az migrálást',
                'Visszalépünk a legelső oldalra és kezdjük a következő cég migrálását'
            ]
        },
        sf1: 'Az adatok rögzítése'
    },
    background: 'Rögzítjük, hogy ki és mikor, mely számlázandó tételeket, mely cégeket migrált. Legközelebb már nem fog megjelenni a migrálandó számlázandó tételek és cégek között. Létrehozzuk a szerződéseket - a terméktípusnak megfelelően - és hozzáadjuk a megfelelő tételeket. Létrehozunk egy szervízfeladatot erről. Minden egyes számlázandó tételről külön-külön'
}
