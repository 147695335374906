export default {
    prelude: 'Kiválasztva egy számlázandó tétel',
    currentJob: 'A kiválasztott számlázandó tétel migrálása',
    users: [],
    actions: {
        oro: {
            desc: 'Ez a fejezet  2 részre osztott. A bal oldalon a migrálandó tétel. Jobb oldalon a művelet.',
            actions: [
                'A jobb oldalon lévő választó segítségével kijelöljük, hogy milyen típusú tételről (milyen termékhez tartozik) van szó és ˙kezeltnek` jelöljük',
                'Kezelés után a tétel eltűnik innen, a `kezelt` tételek közé kerül',
                'Miután minden számlázandó tétel eltűnt a `nem kezeltek` közül, ezen az oldalon készen vagyunk és továbblépünk az ellenőrzés (és mentés) oldalra'
            ]
        },
        sf1: 'Nem történik semmi sem'
    },
    background: 'Nem történik semmi sem'

}
