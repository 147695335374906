export default {
    prelude: 'Kiválasztva egy cég vagy egy számlázási tétel',
    currentJob: 'A cég adatok ellenőrzése és migrálása',
    users: [''],
    example: [
        'Nagyon Security Kft. (Cég)',
        'Wawin Zsámbék (Számlázandó tétel)'
    ],
    actions: {
        oro: {
            desc: 'A cég adatok megjelenítése ami az adminban van és az adatok átvétele',
            actions: [
                'Átvétele a cégadatoknak (adószám, cégjegyzékszám, székhely, postacím, telefonszám, e-mail cím )',
                'Átvétele a kapcsolattartó személyeknek (Mindegyiknek: neve, telefonszáma, e-mail címe)',
                'Számlázási információk, (hány napra fizetnek, milyen módon, a számlákat milyen e-mail címre küldjük)',
                'Cég egyedi igényei: pl: A számlán tüntessünk fel egy egyedi azonosítót (megrendelés azonosító)',
                'A cégnél tárolt olyan dolgokat hogy pl: mennyiért kapja az ellenőrző pontokat'
            ]
        },
        sf1: 'Nem történik semmi sem'
    },
    background: 'Lekéri a cégnévhez tartozó cégeket és számlázandó (CSAK AZ AKTÍVAKAT) tételeket. Ezekkel fogunk dolgozni.'

}
