export default {
    prelude: 'A cég adatai ellenőrizve lettek',
    currentJob: 'A számlázandó tételek migrálása',
    users: [''],
    actions: {
        oro: {
            desc: 'A képernyő 3 fő részre osztva. Az I. részben a számlázandó tételek jelennek meg, amelyek még nincsenek `kezelve`. A II. részben az éppen aktuálisan `kezelt` elem. A III. részben pedig a már `kezelt` elemek vannak',
            actions: [
                'Kiválasztani a még nem kezelt számlázandó tételek közül egyet, ami II. részbe kerül, ahol majd a szükséges további információkat beállítjuk'
            ]
        },
        sf1: 'Nem történik semmi sem'
    },
    background: 'Nem történik semmi sem'

}
