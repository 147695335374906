<template>
  <div class="ui container">

    <div class="ui segment navig" v-if="this.currentStep>0">
      <button @click="prevStep" class="ui green basic button">Vissza az időben</button>
      <button @click="resetStep" class="ui green basic button">Kezdjük előlről</button>

    </div>


    <div class="ui segment inform">

      <div>
        <div class="ui grid">
          <div class="eight wide column">
            <h2 class="ui header">Ez történt eddig</h2>
            <p>
              {{ steps[currentStep].prelude }}
            </p>
          </div>
          <div class="eight wide column">
            <h2 class="ui header">A mostani feladat</h2>
            <p>
              {{ steps[currentStep].currentJob }}
            </p>
          </div>
        </div>
      </div>
    </div>


    <div v-if="1===2" class="ui segment user">
      <div>
        <h2 class="ui header">Végzi:</h2>
        <div class="ui horizontal list">
          <div class="item" v-for="user in steps[currentStep].users" :key="user">
            <div class="content">
              <div class="header">{{ user }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="ui segment" v-if="steps[currentStep].example">
      <h2 class="ui header">Például</h2>
      <p v-for="example in steps[currentStep].example" :key="example">{{ example }}</p>
    </div>


    <div class="ui segment actions">
      <h2 class="ui header">Végrehajtandó műveletek:</h2>


      <div class="ui grid">
        <div class="twelve wide column">
          <div class="ui segment">
            <h3 class="ui header">Migrálás oldalon</h3>
            <div v-if="steps[currentStep].actions.oro.desc" class="ui message" >
              {{ steps[currentStep].actions.oro.desc }}
            </div>
            <div class="ui bulleted list">
              <div class="item" v-for="oroAction in steps[currentStep].actions.oro.actions" :key="oroAction">
                {{
                  oroAction
                }}
              </div>
            </div>
          </div>

        </div>

        <div class="four wide column">
          <div class="ui segment">
            <h3 class="ui header">sf1 Admin</h3>
            {{ steps[currentStep].actions.sf1 }}
          </div>
        </div>

      </div>
    </div>


    <div class="ui segment background" v-if="steps[currentStep].background">
      <h3 class="ui header">Háttérben történik</h3>
      <p>
        {{ steps[currentStep].background }}

      </p>
    </div>

    <div class="ui segment navig" v-if="this.currentStep < this.steps.length-1">
      <button @click="nextStep" class="ui green basic button ">Következő</button>

    </div>


  </div>


</template>

<script>
import steps from './data';

export default {
  name: 'App',
  data() {
    return {
      currentStep: 1,
      steps: steps.steps,
    }
  },

  methods: {
    nextStep() {
      if (this.currentStep < this.steps.length)
        this.currentStep++;
    },
    prevStep() {
      if (this.currentStep > 0) {

        this.currentStep--;
      }
    },
    resetStep() {
      this.currentStep = 0;
    }
  },

  components: {},

}
</script>

<style scoped>
#app {
  margin-top: 1rem;
}

.navig {

}

.inform {
  background-image: linear-gradient(to right, #dcd5cd, #c4d9dc);
}


.user {
  background-image: linear-gradient(to right, #97dbe6, #053067);
}

.actions {
  background-image: linear-gradient(to right, #f7e2ab, #ea9755);
}

.background {
  background-image: linear-gradient(to right, #f3c673, #fcd27e, #f7954a, #aa2f00);
}


</style>
