export default {

    prelude: 'Az sf-oldalról megtudtuk, hogy mely aktív számlázandó tételek vannak és azokhoz milyen cégek tartoznak',
    currentJob: 'A cég kiválasztása',
    example: [
        'Nagyon Security Kft. (Cég)',
        'Wawin Zsámbék (Számlázandó tétel)'
    ],
    users: [''],
    actions: {
        oro: {
            actions:[
                'Kiválasztása a cégnek vagy egy szolgáltaási tételnek',
            ]
        },
        sf1: 'Rögzítjük, hogy valaki elkezdte a migrálást, más már ne fogjon hozzá ennek cégnek (illetve a számlázandó tételek migrálásához)'
    }
}
