import step_01 from "@/data/step_01";

import step_03 from "@/data/step_03";

import step_04 from "@/data/step_04";

import step_05 from "@/data/step_05";
import step_06 from "@/data/step_06";



export default {

    steps: [
        step_01,
        step_03,
        step_04,
        step_05,
        step_06,

    ]
}
